// Global packages or components
import { Card, FormLogin, LoginTabs } from '~/components'

// Type declarations
import type { LoaderFunction } from '@remix-run/node'

// Route loader
export const loader: LoaderFunction = async () => {
  return Response.json(null)
}

// Route view
export default function Index() {
  return (
    <main>
      <div className="container py-10">
        <div className="mx-auto max-w-2xl">
          <Card padding="sm">
            <LoginTabs active="login" />
            <FormLogin />
          </Card>
        </div>
      </div>
    </main>
  )
}
